import React from 'react';
import FormTextField from 'components/FormTextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useForm  } from 'react-hook-form';
import FormDateRangePickerSingle from 'components/FormDateRangePickerSingle';

export default function Filtro({filtra}) {
    const { control, handleSubmit } = useForm({mode: 'all'});

    return (
        <form onSubmit={handleSubmit(filtra)}>
            <Grid container spacing={1}>
                <FormTextField xs={12} md={1} control={control} name="nome_like" label="Nome" />
                <FormTextField xs={12} md={1} control={control} name="cognome_like" label="Cognome" />
                <FormTextField xs={6} md={1} control={control} name="idContratto" label="Numero" />
                <FormTextField xs={6} md={1} control={control} name='strumento_like' label="Matricola" />
                <FormTextField xs={6} md={1} control={control} name='descrizioneMarca_like' label="Marca" />
                <FormTextField xs={6} md={1} control={control} name='via_like' label="Via" />
                <FormTextField xs={6} md={1} control={control} name='localita_like' label="Località" />
                <FormTextField xs={6} md={1} control={control} name='provincia' label="Provincia" />
                <FormDateRangePickerSingle control={control} xs={12} md={2} label='Data ' name='data_daterange' />

                <Grid item xs={12} md={1}>
                    <Button type="submit" style={{height:56}} size="large" variant="outlined" fullWidth>Filtra</Button>         
                </Grid>
            </Grid>
        </form>
    )
}