import React, {useState, useRef, useEffect} from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import api from '../lib/api2';
import { FixedSizeList } from 'react-window';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';

export default function DialogClienti({onSelect, open}) {
  const [clienti, setClienti] = useState(null);
  const [ricerca, setRicerca] = useState('');
  const [isProgressVisible, setProgressVisible] = useState(false);
  const ricercaInputRef = useRef(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        ricercaInputRef.current?.focus();
      }, 0);
    }
  }, [open]);  

  const caricaDati=()=>{
    setProgressVisible(true);
    api.get("/clienti?ricerca="+ricerca).then(data=>{
      setClienti(data);
      setProgressVisible(false);
    })
  }

  const renderRow=({ index, style })=>{
    const cliente=clienti[index];
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={cliente.cognome + " " + cliente.nome} style={{whiteSpace: 'nowrap'}} title={cliente.cognome + " " + cliente.nome} onClick={()=>onSelect(cliente.idCliente)}/>
        </ListItemButton>
      </ListItem>
    );
  }

  const handleAddCliente = () => {
    const url = '/clienti/new';
    window.open(url, '_blank');
  };

  const handleKeyDown=e=>{
    if (e.keyCode===13) caricaDati();
  }
  
  return (
    <Dialog open={open || true} scroll="paper" onClose={()=>onSelect(null)} fullWidth>
      <DialogTitle>
        Selezione cliente
      </DialogTitle>
      <DialogContent>
        <Grid container style={{marginTop:5}}>
          <Grid item xs={10}>
            <TextField inputRef={ricercaInputRef} value={ricerca} onChange={e=>setRicerca(e.target.value)} onKeyDown={handleKeyDown} label='Ricerca' fullWidth  />
          </Grid>
          <Grid item xs={2}>
            <IconButton variant="outlined" onClick={caricaDati} style={{marginTop:8,marginLeft:5}} disabled={ricerca.length<3}><SearchIcon/></IconButton>
          </Grid>
          <Grid item xs={12}>
            <IconButton variant="outlined" onClick={handleAddCliente} style={{marginTop:8,marginLeft:5}}>
              <AddIcon/>
              <Typography>Aggiungi un nuovo cliente</Typography>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {isProgressVisible &&
              <center><CircularProgress /></center>
            }
            {clienti!==null && clienti.length>0 &&
              <FixedSizeList
                height={400}
                width={'100%'}
                itemCount={clienti.length}
                itemSize={46}
                overscanCount={5}
                style={{overflowX: 'hidden'}}
              >
                {renderRow}
              </FixedSizeList>
            }
            {clienti!==null && clienti.length===0 &&
              <center style={{margin:30}}><Typography sx={{ color: "warning.main" }}>Nessun risultato</Typography></center>
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
