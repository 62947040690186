import React, { useState, useEffect } from 'react';
import api from 'lib/api2';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormTextField from 'components/FormTextField';
import FormSelect from 'components/FormSelect';
import { useForm } from 'react-hook-form';

export default function Filtro({ filtra }) {
    const { control, handleSubmit, setValue, watch } = useForm({ mode: 'all' });
    const [strutture, setStrutture] = useState([]);
    const [marche, setMarche] = useState([]);
    const [colori, setColori] = useState([]);
    const [stati, setStati] = useState([]);

    /*
    const optionsBool = [
        { value: '', label: '--Tutti--' },
        { value: '0', label: 'No' },
        { value: '1', label: 'Sì' },
    ];
    */

    useEffect(() => {
        const caricaTabelle = async () => {
            await api.get('/strumenti/strutture?sort=descrizioneStruttura').then(data => {
                data.unshift({ idStruttura: '', descrizioneStruttura: '-' });
                setStrutture(data);
            });
            await api.get('/strumenti/marche?sort=descrizioneMarca').then(data => {
                data.unshift({ idMarca: '', descrizioneMarca: '-' });
                setMarche(data);
            });
            await api.get('/strumenti/colori?sort=descrizioneColore').then(data => {
                data.unshift({ idColore: '', descrizioneColore: '-' });
                setColori(data);
            });
            await api.get('/strumenti/stati?sort=descrizioneStato').then(data => {
                data.unshift({ codiceStato: '', descrizioneStato: '-' });
                data.unshift({ codiceStato: 'DN,DU', descrizioneStato: 'Nuovo + usato' });
                data.unshift({ codiceStato: 'cespiti', descrizioneStato: 'Cespiti' });
                data.sort((a, b) => a.descrizioneStato.localeCompare(b.descrizioneStato));
                setStati(data);
            });
        };
        caricaTabelle();
    }, []);

    const statoSelezionato = watch('codiceStato'); // Osserva il valore dello stato

    useEffect(() => {
        if (statoSelezionato === 'cespiti') {
            setValue('cespite', '1'); // Forza cespite=1
        } else if (statoSelezionato !== 'cespiti' && statoSelezionato !== undefined) {
            setValue('cespite', ''); // Resetta cespite se non è "Cespiti"
        }
    }, [statoSelezionato, setValue]);

    return (
        <form onSubmit={handleSubmit(filtra)}>
            <Grid container spacing={1}>
                <FormTextField xs={12} md={2} control={control} name="matricola_like" label="Matricola" />
                <FormSelect xs={12} md={2} control={control} name="descrizioneStruttura" label="Struttura" options={strutture} valueField="descrizioneStruttura" labelField="descrizioneStruttura" />
                <FormSelect xs={12} md={2} control={control} name="descrizioneMarca" label="Marca" options={marche} valueField="descrizioneMarca" labelField="descrizioneMarca" />
                <FormTextField xs={12} md={2} control={control} name="modello_like" label="Modello" />
                <FormSelect xs={12} md={2} control={control} name="descrizioneColore" label="Colore" options={colori} valueField="descrizioneColore" labelField="descrizioneColore" />

                <FormSelect xs={12} md={2} control={control} name="codiceStato" label="Stato" options={stati} valueField="codiceStato" labelField="descrizioneStato" />
                <FormTextField xs={12} md={2} control={control} name="valoreCommerciale_geq" label="Valore commerciale (min)" />
                <FormTextField xs={12} md={2} control={control} name="strumenti.valoreCommerciale_leq" label="Valore commerciale (max)" />
                <FormTextField xs={12} md={2} control={control} name="annoProduzione_geq" label="Anno produzione (min)" />
                <FormTextField xs={12} md={2} control={control} name="strumenti.annoProduzione_leq" label="Anno produzione (max)" />
                {/* <FormSelect xs={12} md={1} control={control} name="cespite" label="Cespite" options={optionsBool} /> */}
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={2}>
                    <Button type="submit" style={{ height: 56 }} size="large" variant="outlined" fullWidth>
                        Filtra
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
