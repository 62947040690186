import React, {useState, useRef, useEffect} from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import api from '../lib/api2';
import { FixedSizeList } from 'react-window';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function DialogStrumenti({onSelect, open, includiRiparazioni, contrattoTipo}) {
  const [strumenti, setStrumenti] = useState(null);
  const [ricerca, setRicerca] = useState('');
  const [stato, setStato] = useState('D%');
  const [isProgressVisible, setProgressVisible] = useState(false);
  const ricercaInputRef = useRef(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        ricercaInputRef.current?.focus();
      }, 0);
    }
  }, [contrattoTipo, open]); 

  const caricaDati=()=>{
    setProgressVisible(true);
    var url="/strumenti?ricerca="+ricerca;
    if (stato) url+="&stato="+stato+"|like";
    api.get(url).then(data=>{
      setStrumenti(data);
      setProgressVisible(false);
    })
  }

  const statoSelect = (e) => {
    setStato(e.target.value);
  }

  const renderRow=({ index, style })=>{
    const strumento=strumenti[index];
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={strumento.descrizioneTipoStrumento+" "+strumento.descrizioneMarca+" "+strumento.descrizioneStruttura+" "+strumento.modello+" - "+strumento.matricola+" - "+strumento.descrizioneStato} style={{whiteSpace: 'nowrap'}} title={strumento.descrizioneTipoStrumento+' '+strumento.descrizioneMarca+' '+strumento.descrizioneStruttura+" "+strumento.modello+' - '+strumento.matricola} onClick={()=>onSelect(strumento.matricola)}/>
        </ListItemButton>
      </ListItem>
    );
  }

  const handleKeyDown=e=>{
    if (e.keyCode===13) caricaDati();
  }

  return (
    <Dialog open={open || true} scroll="paper" onClose={()=>onSelect(null)} fullWidth>
      <DialogTitle>
        Selezione strumenti
      </DialogTitle>
      <DialogContent>
        <Grid container style={{marginTop:5}}>
          <Grid item xs={7}>
            <TextField inputRef={ricercaInputRef} value={ricerca} onChange={e=>setRicerca(e.target.value)} onKeyDown={handleKeyDown} label='Ricerca' fullWidth />
          </Grid>
          {!includiRiparazioni && (
            <Grid item xs={4}>
              <FormControl sx={{ m: 0, marginLeft: 1 }} fullWidth>
                <InputLabel id="stato-label">Stato</InputLabel>
                <Select
                  labelId="stato-label"
                  id="stato"
                  value={stato}
                  onChange={statoSelect}
                  label="Stato"
                  disabled={contrattoTipo === "V" || contrattoTipo === "N"}
                >
                  <MenuItem value="">Tutti</MenuItem>
                  <MenuItem value="DN">Nuovo</MenuItem>
                  <MenuItem value="D%">Nuovo + usato</MenuItem>
                  <MenuItem value="DU">Usato</MenuItem>
                  <MenuItem value="V">Venduto</MenuItem>
                  <MenuItem value="N">Noleggiato</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {includiRiparazioni && (
            <Grid item xs={4}>
              <TextField value="in riparazione" label='Strumenti' fullWidth disabled/>
            </Grid>
          )}
          <Grid item xs={1}>
            <IconButton variant="outlined" onClick={caricaDati} style={{marginTop:8,marginLeft:5}} disabled={ricerca.length < 2 && stato === ""}><SearchIcon/></IconButton>
          </Grid>
          <Grid item xs={12}>
            {isProgressVisible &&
              <center><CircularProgress /></center>
            }
            {strumenti!==null && strumenti.length>0 &&
              <FixedSizeList
                height={400}
                width={'100%'}
                itemCount={strumenti.length}
                itemSize={46}
                overscanCount={5}
                style={{overflowX: 'hidden'}}
              >
                {renderRow}
              </FixedSizeList>
            }
            {strumenti!==null && strumenti.length===0 &&
              <center style={{margin:30}}><Typography sx={{ color: "warning.main" }}>Nessun risultato</Typography></center>
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
