import React, { useState, useEffect } from 'react';
import api from 'lib/api2';
//import { useNavigate } from "react-router-dom";
import { DataGridPro, GridToolbar, gridClasses } from '@mui/x-data-grid-pro';
import { DateTime } from "luxon";

const Elenco = React.memo(({queryString, apiRef, setSelectedRows})=>{
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [classi, setClassi] = useState([]);
    //const navigate = useNavigate();
    
	const columns = [
    { field: 'idDocumento', headerName: 'ID', hide: true },
    { field: 'tipoDocumento', headerName: 'Tipo', width: 80 },
    { field: 'annullato', headerName: 'Annullato', width: 90, valueFormatter: params=> params.value==='1'?'Sì':'' },
    { field: 'numero', headerName: 'Numero', width: 80 },
    { field: 'data', headerName: 'Data', width: 130, valueFormatter: params => DateTime.fromSQL(params?.value).toLocaleString({day:'2-digit', month:'2-digit', year:'numeric'}) },
    { field: 'nominativo', headerName: 'Nominativo', flex:1 },
    /*
    { field: 'totaleDocumento', headerName: 'Importo', width: 100, type:'number', valueFormatter: params => { eturn new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
    */
    { field: 'pagata', headerName: 'Pagata', width:80, valueFormatter: params=> params.value==="1"?'Sì':'No'},
		{ field: 'feInvio', headerName: 'Fattura elettronica inviata il', width: 160, valueFormatter: params => params?.value ? DateTime.fromSQL(params?.value).toFormat("dd/MM/yyyy HH:mm:ss") : "", cellClassName: c => c.row.feInvio ? "row-green" : ''},
		{ field: 'feErrorDescription', headerName: 'Esito', width:120, cellClassName: c => c.row.feErrorDescription ? "row-green" : '' },
  	]

    useEffect(()=>{
      const classi = [];
      classi.push({[`& .${gridClasses.cell}`]: {
        py: 2,
      }});
      classi.push({ '& .row-green': { backgroundColor: "rgba(0, 180, 0, 0.8)!important" } });
      setClassi(classi);
    }, [])

    useEffect(()=>{
      setLoading(true);
      api.get("/documenti?"+queryString).then(data=>{
        setRows(data);
        setLoading(false);
      });
    }, [queryString])

    const handleSelectionChange = (selectedIds) => {
      const selectedRowsData = rows.filter((row) => selectedIds.includes(row.idDocumento));
      setSelectedRows(selectedRowsData);
    };

    return	<DataGridPro
        slots={{ toolbar: GridToolbar }} 
        loading={loading}
        columns={columns}
        rows={rows}
        apiRef={apiRef}
        hideFooterPagination
        sortingMode="server"
        filterMode="server"
        rowsLoadingMode="server"
        autoHeight={false}
        sx={classi}
        getRowId={r=>r.idDocumento}
        getCellClassName={r=>"noedit"}
        showCellRightBorder={false}
        showColumnRightBorder={true}
        checkboxSelection={true}
        onRowClick={ r=>window.open( "/documenti/"+r.id ) }
        onSelectionModelChange={handleSelectionChange}
        throttleRowsMs={500}
    />
});


export default Elenco;