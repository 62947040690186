import React, { useState, useEffect, useCallback } from 'react';
import api from '../lib/api2';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { useForm, useWatch } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ContextCommand from 'components/ContextCommand';
import useNotification from 'components/useNotification';
import FormTitle from 'components/FormTitle';
import FormTextField from 'components/FormTextField';
import FormCheckbox from 'components/FormCheckbox';
import FormCliente from 'components/FormCliente';
import FormStrumento from 'components/FormStrumento';
import FormSelect from 'components/FormSelect';
import FormTinyMCE from "components/FormTinyMCE";
import Archivio from 'components/Archivio';
import { nullZLSTabella } from 'lib/dati';
import { useNavigate } from "react-router-dom";
import SimpleDataGrid from 'components/SimpleDataGrid';
import { DateTime } from "luxon";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useReport from 'components/Report/useReport.js';
import moment from 'moment';
import { TextField } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import ScadenzeContratto from './ScadenzeContratto.js';
import DialogModelliContratti from './DialogModelliContratti';
import DialogCampi from 'components/DialogCampi';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export default function Contratti({setTitle, setContextCommands}) {
	const { tipo } = useParams();
	const { id } = useParams();
	const navigate = useNavigate();
	const { pushNotification } = useNotification();
	const { control, handleSubmit, getValues, setValue, reset } = useForm({mode: 'all'});
	const [ isLoaderOpen, setLoaderOpen ] = useState(false);
	const [ contrattoTipo, setContrattoTipo ] = useState([]);
	const [ codiciIVA, setCodiciIVA ] = useState([]);
	const [ destinazioniStrumento, setDestinazioniStrumento ] = useState([]);
	const [ contrattiAccontiPagamento, setContrattiAccontiPagamento ] = useState([]);
	const [ marche, setMarche ] = useState([]);

	const cliente = useWatch({ control, name: "cliente"});
	const destinazioneStrumento = useWatch({ control, name: "destinazioneStrumento"});
	const { openReport } = useReport();
	const anticipo = useWatch({ control, name: "anticipo"});
	const prezzo = useWatch({ control, name: "prezzo"});
	const permutaValore = useWatch({ control, name: "permutaValore"});
	const permutaMarca = useWatch({ control, name: "permutaMarca"});
	const permutaModello = useWatch({ control, name: "permutaModello"});
	const permutaMatricola = useWatch({ control, name: "permutaMatricola"});
	const acconto1 = useWatch({ control, name: "acconto1"});
	const acconto2 = useWatch({ control, name: "acconto2"});
	const acconto3 = useWatch({ control, name: "acconto3"});
	const acconto4 = useWatch({ control, name: "acconto4"});
	const codiceIva = useWatch({ control, name: "codiceIva"});
	const valore = useWatch({ control, name: "valore"});
	const trasportoAndata = useWatch({ control, name: "trasportoAndata"});
	const trasportoRitorno = useWatch({ control, name: "trasportoRitorno"});
	const data = useWatch({ control, name: "data"});
	const [ riepilogo, setRiepilogo ] = useState({canone:0,canoneIvato:0,primoCanone:0,primoCanoneIvato:0,scadenza2:'',scadenza3:'',scadenza4:'', riscatto:0});
	const [ pagamentiMetodi, setPagamentiMetodi ] = useState([]);
	const salvaScadenze = React.useRef();
	const [isDialogModelliOpen, setDialogModelliOpen]=useState(false);
	const [isDialogCampiOpen, setDialogCampiOpen] = useState(false);
	const [isTestoModelloVisible,setTestoModelloVisible]=useState(false);
	const [expanded, setExpanded] = useState(true); // true per essere aperto di default
	const [ivaDefault,setIvaDefault] = useState(0);

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

	useEffect(() => {
		const caricaTabelle=async ()=>{
			await api.get('/strumenti/marche?sort=descrizioneMarca').then(data=>{
				data.unshift( {idMarca:"", descrizioneMarca:"-"} );
				setMarche(data);
			});
		}
		caricaTabelle();
  	}, []);
	
	const updatePrezzo = useCallback((valore, ivaInclusa) => {

		if (valore === undefined || valore === null || isNaN(parseFloat(valore))) {
			return;
		}
		valore = parseFloat(valore.toString().replace(',', '.'));

		const ci = getValues('codiceIva') || ivaDefault;
		const ai = ci ? codiciIVA.find(i => i.codiceIVA === ci.toString())?.aliquotaIva ?? 0 : 0;
		if (ivaInclusa) {
			const prezzoSenzaIva = Math.round(valore / (1 + ai / 100)*10000)/10000;

			setValue('prezzo', prezzoSenzaIva, { shouldValidate: false, shouldDirty: false });
		} else {
			//const millesimi = (valore * 100) - Math.floor(valore * 100);
			//if (millesimi>=0.5 && millesimi<0.6)	valore+=0.001;
			
			const prezzoConIva = Math.round(valore * (1 + ai / 100)*100)/100;


			setValue('prezzoIvaInclusa',prezzoConIva, { shouldValidate: false, shouldDirty: false });
		}
	}, [setValue, getValues, codiciIVA, ivaDefault]);

	useEffect(()=>{
		const canone = parseFloat(prezzo || 0)*parseInt(anticipo || 0);
		const aliquotaIva = codiceIva ? codiciIVA.find(i=>i.codiceIVA===codiceIva.toString())?.aliquotaIva : 0;
		const canoneIvato = canone*(1+aliquotaIva)/100;
		const primoCanone = canone+parseFloat(trasportoAndata || 0)+parseFloat(trasportoRitorno || 0);
		const primoCanoneIvato = primoCanone*(1+aliquotaIva)/100;
		updatePrezzo(prezzo, false);
		const scadenza2 = moment(data).add(anticipo,'M').format("DD/MM/YYYY");
		const scadenza3 = moment(data).add(anticipo*2,'M').format("DD/MM/YYYY");
		const scadenza4 = moment(data).add(anticipo*3,'M').format("DD/MM/YYYY");
		const riscatto = parseInt(valore || 0) - ( parseInt(prezzo || 0) * 12 ) - parseInt(trasportoRitorno || 0);
		const riepilogoNew={canone,canoneIvato,primoCanone,primoCanoneIvato,scadenza2,scadenza3,scadenza4,riscatto};
		setRiepilogo(riepilogoNew);
	}, [anticipo, prezzo, codiceIva, codiciIVA, valore, trasportoAndata, trasportoRitorno, data, updatePrezzo]);

	const columnsDDT = [
		{ field: 'idDocumento', headerName: 'ID', width: 100, align: 'right' },
		{ field: 'numero', headerName: 'Numero', width: 100, align: 'right' },
		{ field: 'data', headerName: 'Data', flex:1, type:'date', valueGetter:params=>DateTime.fromSQL(params.value).toFormat('dd/MM/yyyy')  },
		{ field: '_1', headerName: '', width: 120, type:'actions', editable: true, getActions: params => [
			<GridActionsCellItem label='' icon={<OpenInNewIcon/>} onClick={()=>window.open("/documenti/"+params.row.idDocumento)}  />,
		]},
	]

	const columnsEsiti = [
		{ field: 'id', headerName: 'ID', width: 60, align: 'right' },
		{ field: 'destinatario', headerName: 'Destinatario', flex: 1, align: 'left' },
		{ field: 'dataOraInvio', headerName: 'Data ed ora', width: 250, type:'string', valueGetter:params=>DateTime.fromSQL(params.value).toFormat('dd/MM/yyyy HH:mm:ss')  },
		{ field: 'esito', headerName: 'Esito', width: 200, align: 'left' },
	]

	useEffect(() => {
		const saldoValue = parseFloat(getValues('prezzoIvaInclusa') || 0) - parseFloat(permutaValore || 0) - parseFloat(acconto1 || 0) - parseFloat(acconto2 || 0) - parseFloat(acconto3 || 0) - parseFloat(acconto4 || 0);
		setValue('saldo', saldoValue.toFixed(2));
	}, [prezzo, permutaValore, acconto1, acconto2, acconto3, acconto4, setValue, getValues]);

	useEffect(() => {
		setLoaderOpen(true);
		api.get('/iva').then(data=>{
			setCodiciIVA(data);
		});
		api.get('/contrattiAccontiPagamento').then(data=>{
			setContrattiAccontiPagamento(data);
		});
		api.get('/pagamentiMetodi').then(data=>{
			setPagamentiMetodi(data);
		});
  	}, []);

	useEffect(()=>{
		if (cliente) {
			api.get('/clienti/'+cliente+'/indirizzi').then(data=>{
				setDestinazioniStrumento(data.map(d=>({...d,indirizzoCompilato:d.descrizioneIndirizzo+' '+d.via+', '+d.civico+' - '+d.cap+' '+d.localita+' ('+d.provincia+')'})));
			});
		}
	}, [cliente]);

	const handleStrumentoChange = matricola => {
		if (matricola) {
			const tipoMaiuscolo = tipo.toUpperCase();
			api.get('/strumenti/dettagli/'+matricola).then((data) => {
				const strumentoSelezionato = data[0];
				setValue('statoStrumentoStipula', strumentoSelezionato.stato==='DN'?'Nuovo':'Usato');
				if (tipoMaiuscolo === 'N') {
						setValue('valore', strumentoSelezionato.valoreNoleggio || '');
				} else if (tipoMaiuscolo === 'V') {
					if (strumentoSelezionato.acquistatoDaPrivato==='1')	{
						setValue('codiceIva','N5');
						setValue('prezzo', strumentoSelezionato.valoreCommerciale);

					}	else	{
						// console.log(ivaDefault);
						setValue('codiceIva', ivaDefault);
						updatePrezzo(strumentoSelezionato.valoreCommerciale, true);
					}
					setValue('valore', strumentoSelezionato.valoreCommerciale || '');
					setValue('prezzoIvaInclusa', strumentoSelezionato.valoreCommerciale || '');
					
					//setValue('prezzo', (parseFloat(strumentoSelezionato.valoreCommerciale || '')/(1+aliquotaIva/100)).toFixed(2) );
					setValue('stato', strumentoSelezionato.descrizioneStato || '');
					
				} else if (tipoMaiuscolo === 'C') {
						setValue('valore', strumentoSelezionato.valoreCommerciale || '');
				}
			});
		  }
	};

	useEffect(()=>{
		if (!destinazioneStrumento) return;
		const destinazione=destinazioniStrumento.find(d=>d.idIndirizzo===destinazioneStrumento);
		setValue('piano', destinazione?.piano)
	}, [destinazioneStrumento, destinazioniStrumento, setValue]);

	const handleClienteChange = cliente => {
		api.get('/clienti/'+cliente).then(data=>{
			if (data.length===0) {
				setValue('destinazioneStrumento','')
				setValue('legaleRappresentante','')
			}	else	{
				setValue('destinazioneStrumento', data[0].indirizzoDestinazione)
				setValue('legaleRappresentante', data[0].legaleRappresentante)
			}
		});
	}

	useEffect(()=>{
		setLoaderOpen(true);
		api.get('/configurazione?chiave=ivaDefault').then(data => {
			if (data.length > 0) {
				// console.log('setivadefault', data[0].valore)
				if (id === 'new') setValue('codiceIva', data[0].valore);
				setIvaDefault(data[0].valore);
			}
		});
		if (id==='new') {
			setValue('_action', 'n');
			const oggi = DateTime.now().toFormat('yyyy-MM-dd');
			setValue('data', oggi);
			setValue('tipoContratto', tipo.toUpperCase());

			setLoaderOpen(false);
		} else {
			api.get(`/contratti/${id}`).then(data=>{
				if (data.length===0) {
					alert('Errore nel caricamento dei dati');
				} else {
					const contratto = data[0];
					contratto._action="e";
					api.get('/contratti/tipi?codice='+tipo.toUpperCase()).then(t=>{
						if (t.length>0)	setContrattoTipo(t[0].titolo);
						else setContrattoTipo('');
					});
					const descrzioneTipoContratto = (contratto.tipoContratto ? contrattoTipo : "" );
					setTitle(descrzioneTipoContratto+" - "+contratto.idContratto+"/"+new Date(contratto.data).getFullYear());
					api.get('/strumenti/dettagli/'+contratto.strumento).then((data) => {
						const strumentoSelezionato = data[0];
						setValue('stato', strumentoSelezionato.descrizioneStato || '');
					})
					reset(contratto);
					updatePrezzo(contratto.prezzo, false);
				}
				setLoaderOpen(false);
			});
		}
		// eslint-disable-next-line
	}, [id, tipo, setTitle, contrattoTipo, setValue, reset]);

	useEffect(()=>{
		api.get('/contratti/tipi?codice='+tipo.toUpperCase()).then(t=>{
			if (t.length>0)	setContrattoTipo(t[0].titolo);
			else setContrattoTipo('');
		});
		if (id==='new')	{
			setTitle("Nuovo contratto: "+contrattoTipo);
		}
	}, [id, tipo, setTitle, getValues, contrattoTipo]);

	const salva = useCallback(contratto=>{
		delete contratto.stato;
		delete contratto.prezzoIvaInclusa;
		const data = nullZLSTabella([{ ...contratto, modelloContratto: undefined, idDocumento: undefined, numero: undefined, tipoDocumento: undefined, documentoData: undefined }]);
		// console.log(JSON.stringify(data));
		api.post("/contratti", data).then(newid=>{
			pushNotification("Dati salvati", "success");
			salvaScadenze.current();
			if (id==='new') navigate ('/contratti/'+tipo+'/'+newid);
		}, async error=>{
			pushNotification("Errore nel salvataggio.", "error");
		});
	}, [pushNotification, navigate, tipo, id]);

	const caricaModelloContratto=(modello)=>{
		api.get('/contratti/'+id+'/modelli/sostituisci/'+modello.idModelloContratto).then(t=>{
			setValue('contrattoTesto', t.testoNuovo);
			if (modello) {
				// setValue('contrattoTesto', modello.modello);
				setValue('contrattoModello', modello.descrizione);
			}
		});
		setDialogModelliOpen(false);
	}

	const creaDDT = useCallback(data=>{
		var ddt = {};
		ddt._action = "n";
		ddt.tipoDocumento = "DDT";
		ddt.cliente = data.cliente;
		ddt.contratto=id;
		if (data.panchina)	ddt.panchina = data.panchina;
		if (data.accessorio1)	ddt.accessorio1 = data.accessorio1;
		if (data.accessorio2)	ddt.accessorio2 = data.accessorio2;
		switch (data.tipoContratto) {
			case "V":
				ddt.ddtCausale="1";
				break;
			case "N":
				ddt.ddtCausale="2";
				break;
			case "R":
				ddt.ddtCausale="4";
				break;
			default:
				break;
		}
		ddt.indirizzo=data.destinazioneStrumento;
		ddt.ddtMezzo = "Vettore";
		ddt.data = DateTime.now().toFormat('yyyy-MM-dd');
		api.get('/strumenti?matricola='+data.strumento).then(strumento=>{
			if (!strumento[0].matricola) {
				ddt.descrizioneStrumento = "";
			} else {
				ddt.descrizioneStrumento = strumento[0].descrizioneTipoStrumento+' '+strumento[0].descrizioneStruttura+" "+strumento[0].descrizioneMarca+" "+strumento[0].modello+' - '+strumento[0].matricola;
			}
			api.post("/documenti/ddt", ddt).then(r=>{
				pushNotification("Dati salvati", "success");
				window.open("/documenti/"+JSON.parse(r));
			}, error=>{
				pushNotification("Errore nel salvataggio del documento", "error");
				console.error(error);
				console.error(JSON.stringify(ddt))
			});
		});
	}, [pushNotification, id]);

	const inviaMailContratto = useCallback ( data => {
		if (!window.confirm("Confermare l'invio delle mail?"))
			return;
		api.post("/contratti/"+data.idContratto+"/mail", "").then(d=>{
			pushNotification("Mail inviata", "success");
		}, error=>{
			pushNotification("Errore nell'invio della mail", "error");
		});
	}, [pushNotification]);

	const stampa = useCallback ( () => {
		const contrattoTesto = getValues('contrattoTesto');
		if (!contrattoTesto)	{
			alert('Attenzione, testo del contratto mancante. Caricare un template o salvare il contratto.');
			return;
		}
		openReport("/report/contratti/"+id);
	}, [id, openReport, getValues]);

	/*
	const stampaAcquisto = useCallback ( () => {
		openReport("/report/acquisto/"+id);
	}, [id, openReport]);
	*/

	const elimina = useCallback(() => {
		api.get('/documenti?contratto='+id).then(count=>{
			if (count.length > 0) {
				pushNotification("Errore nella cancellazione sono presenti documenti collegati", "error");
			} else {
				const confermaEliminazione = window.confirm("Sei sicuro di voler eliminare questo contratto?");
				if (confermaEliminazione) {
					api.delete("/contratti/"+id, []).then(r=>{
						pushNotification("Dati cancellati", "success");
						navigate("/contratti/"+tipo)
					}, error=>{
						pushNotification("Errore nella cancellazione", "error");
					});
					return true;
				}
				return false;
			}
		});
	}, [id, tipo, pushNotification, navigate]);
	
	const creaStrumento = useCallback((cliente, valore, marca, modello, matricola) => {
		if (!valore || !marca || !modello || !matricola ) {
			pushNotification("Errore nella creazione dello strumento in permuta. Manca un dato della permuta.", "error");
		} else {
			if (window.confirm("Sei sicuro di voler creare lo strumento?")) {
				const oggi = DateTime.now().toFormat('yyyy-MM-dd');
				api.get('/clienti/'+cliente).then(c=>{
					const denominazioneFornitore = c[0]['cognome']+" "+c[0]['nome'];
					const data = nullZLSTabella([{ _action: "n", fornitore: denominazioneFornitore, matricola: matricola, marca: marca, modello: modello, valoreAcquisto: valore, tipoStrumento: 1, acquistatoDaPrivato: 1, acqUsato: 1, dataAcquisto: oggi, struttura: null, colore: null }]);
					api.post("/strumenti", data).then(()=>{
						pushNotification("Strumento creato", "success");
					}, async error=>{
						// const descrizioneErrore = await error.text();
						// pushNotification("Errore nella creazione: " + descrizioneErrore, "error");
						pushNotification("Errore nella creazione: strumento già presente con la stessa matricola.", "error");
					});			
				});
			}
		}
	}, [pushNotification]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/contratti/"+tipo) }>Indietro</ContextCommand>,
			<ContextCommand key="2" icon="delete" onClick={ elimina }>Elimina</ContextCommand>,
			<ContextCommand key="3" disabled={id==='new'} icon="printer" onClick={ handleSubmit(data=>creaDDT(data)) }>Crea DDT</ContextCommand>,
			<ContextCommand key="4" disabled={id==='new'} icon="mail" onClick={ handleSubmit(data=>inviaMailContratto(data)) }>Invia MAIL</ContextCommand>,
			<ContextCommand key="5" disabled={id==='new'} icon="file" onClick={ handleSubmit(data=>stampa(data)) }>Stampa</ContextCommand>,
			<ContextCommand key="6" icon="floppy" onClick={ handleSubmit(data=>salva(data)) }>Salva</ContextCommand>
		]);
  	}, [setContextCommands, handleSubmit, navigate, elimina, salva, creaDDT, inviaMailContratto, stampa, tipo, id] );

	const handleCauzioneKeyUp=e=>{
		const cauzione=parseFloat(e.target.value || 0);
		const valore=parseFloat(getValues('valore') || 0);
		setValue('cauzionePercentuale', (valore ? cauzione/valore*100 : 0).toFixed(2) );
	}

	const handleCauzionePercentualeKeyUp=e=>{
		const cauzionePercentuale=parseFloat(e.target.value || 0);
		const valore=parseFloat(getValues('valore') || 0);
		setValue('cauzione', (valore*cauzionePercentuale/100).toFixed(2) );
	}

	return (
		<form>
			{isDialogModelliOpen && <DialogModelliContratti onClose={caricaModelloContratto} />}
			{isDialogCampiOpen && <DialogCampi onClose={()=>setDialogCampiOpen(false)} />}
			

			<Loader open={isLoaderOpen} />
			<Grid container spacing={1}>
				<FormTextField xs={6} md={2} control={control} name="idContratto" label="ID" disabled={true} />
				<FormTextField xs={6} md={3} control={control} name="data" label="Data" type="date" required/>

				<FormCliente xs={12} md={7} control={control} name="cliente" label="Cliente" onSelect={ handleClienteChange } required />
				{ tipo.toUpperCase() === "V" ? (
						<>
							<FormStrumento xs={12} md={3} control={control} name="strumento" label="Strumento" onSelect={ handleStrumentoChange } includiRiparazioni={tipo.toUpperCase() === "R"} contrattoTipo={tipo.toUpperCase()}/>
							<FormTextField xs={12} md={1.5} control={control} name="stato" label="Stato" type="text" disabled />
							<FormTextField xs={12} md={1.5} control={control} name="statoStrumentoStipula" label="Stato alla stipula" type="text" disabled />
						</>
					) : (
						<FormStrumento xs={12} md={6} control={control} name="strumento" label="Strumento" onSelect={ handleStrumentoChange } includiRiparazioni={tipo.toUpperCase() === "R"} contrattoTipo={tipo.toUpperCase()}/>
				)}
				<FormSelect xs={12} md={4} control={control} name='destinazioneStrumento' label="Destinazione strumento" options={destinazioniStrumento} valueField='idIndirizzo' labelField="indirizzoCompilato" />
				<FormTextField xs={12} md={2} control={control} name="piano" label="Piano di consegna" type="text" />

				
				<FormTextField xs={6} md={3} control={control} name="prezzo" label={(tipo.toUpperCase() === "C" ? "Costo noleggio" : tipo.toUpperCase() === "N" ? "Canone mensile" : "Prezzo") + ' (IVA esclusa)'} type="number" controlProps={{ /*onBlur: e => updatePrezzo(e.target.value, false)*/ }} />
				<FormTextField xs={6} md={3} control={control} name="prezzoIvaInclusa" label={(tipo.toUpperCase() === "C" ? "Costo noleggio" : tipo.toUpperCase() === "N" ? "Canone mensile" : "Prezzo") + ' (IVA inclusa)'} type="number" controlProps={{ onBlur: e => updatePrezzo(e.target.value, true) }} />
				<FormSelect xs={6} md={6} control={control} name='codiceIva' label="Codice IVA" options={codiciIVA} valueField='codiceIVA' labelField='descrizioneIva' />
				
				{
					( tipo.toUpperCase() === "C" ) &&
						<>
							<Accordion elevation={1} style={{width:'100%'}} expanded={expanded} onChange={handleAccordionChange} >
							<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor:'rgb(214, 190, 156)'}}>
								<Typography>
									Dati noleggi per concerti
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={1} style={{marginTop:0}}>
									<FormTextField xs={6} md={2} control={control} name="valore" label="Valore dello strumento" type="number" />
									<FormTextField xs={6} md={2} control={control} name="cauzione" label="Cauzione" type="number" onKeyUp={handleCauzioneKeyUp} />
									<FormTextField xs={6} md={1} control={control} name="cauzionePercentuale" label="Cauzione (%)" type="number" onKeyUp={handleCauzionePercentualeKeyUp} />
									<FormSelect xs={12} md={5} control={control} name='cauzioneMetodo' label="Metodo pagamento cauzione" options={pagamentiMetodi} valueField='codice' labelField='descrizione' />
									<FormTextField xs={6} md={2} control={control} name="penale" label="Penale" type="number" />

									<FormTextField xs={6} md={6} control={control} name="inizioConcerto" label="Data inizio" type="date"/>
									<FormTextField xs={6} md={6} control={control} name="dataChiusura" label="Data fine" type="date" controlProps={{ inputProps: { style: { backgroundColor: '#FF7F7F' } } }}/>
									
									<FormTextField xs={6} md={3} control={control} name="dataConsegna" label="Data di consegna" type="date"/>
									<FormTextField xs={6} md={3} control={control} name="oraConsegna" label="Ora di consegna" type="text"/>
									<FormTextField xs={6} md={3} control={control} name="dataRitiro" label="Data di ritiro" type="date"/>
									<FormTextField xs={6} md={3} control={control} name="oraRitiro" label="Ora di ritiro" type="text"/>

									<FormTextField xs={6} md={4} control={control} name="dataAccordatura" label="Data accordatura" type="date"/>
									<FormTextField xs={6} md={4} control={control} name="oraAccordatura" label="Orario" type="text"/>
									<FormTextField xs={6} md={4} control={control} name="costoAccordatura" label="Costo" type="number"/>

									<FormTextField xs={12} md={12} control={control} name="condizioniTermini" label="Condizioni e termini pagamento" type="text"/>
								</Grid>
							</AccordionDetails>
						</Accordion>
						</>
				}
				
				{
				( tipo.toUpperCase() === "N" ) &&
					<>
						<Accordion elevation={1} style={{width:'100%'}} expanded={expanded} onChange={handleAccordionChange} >
							<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor:'rgb(214, 190, 156)'}}>
								<Typography>
									Dati noleggi
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={1} style={{marginTop:0}}>
									<FormTextField xs={12} md={2} control={control} name="anticipo" label="Anticipo" type="number" required InputProps={{ inputProps: { min: 1 } }}/>
									<FormTextField xs={6} md={2} control={control} name="trasportoAndata" label="Trasporto (andata)" type="number" />
									<FormTextField xs={6} md={2} control={control} name="trasportoRitorno" label="Trasporto (ritorno)" type="number" />
									<FormTextField xs={6} md={2} control={control} name="cauzione" label="Cauzione" type="number" onKeyUp={handleCauzioneKeyUp} />
									<FormTextField xs={6} md={1} control={control} name="cauzionePercentuale" label="Cauzione (%)" type="number" onKeyUp={handleCauzionePercentualeKeyUp} />
									<FormSelect xs={12} md={3} control={control} name='cauzioneMetodo' label="Metodo pagamento cauzione" options={pagamentiMetodi} valueField='codice' labelField='descrizione' />
									
									<FormTextField xs={6} md={2} control={control} name="valore" label="Valore dello strumento" type="number" />
									<FormTextField xs={6} md={2} control={control} name="penale" label="Penale" type="number" />

									<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.riscatto.toFixed(2)} InputLabelProps={{shrink:true}} label='Riscatto noleggio' /></Grid>
									<FormTextField xs={6} md={2} control={control} name="dataChiusura" label="Data chiusura" type="date" controlProps={{ inputProps: { style: { backgroundColor: '#FF7F7F' } } }} />

									<FormTextField xs={12} md={12} disabled control={control} name="legaleRappresentante" label="Legale rappresentante" type="text" />
									{ /*
									<FormTextField xs={6} md={4} control={control} name="dataAccordatura" label="Data accordatura" type="date"/>
									<FormTextField xs={6} md={4} control={control} name="oraAccordatura" label="Orario" type="text"/>
									<FormTextField xs={6} md={4} control={control} name="costoAccordatura" label="Costo" type="number"/>
									*/ }
								</Grid>
							</AccordionDetails>
						</Accordion>

						<FormTitle variant="h6">Riepilogo</FormTitle>
						<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.canone.toFixed(2)} InputLabelProps={{shrink:true}} label='Canone periodico' /></Grid>
						<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.canoneIvato.toFixed(2)} InputLabelProps={{shrink:true}} label='Canone periodico (IVA inclusa)' /></Grid>
						<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.primoCanone.toFixed(2)} InputLabelProps={{shrink:true}} label='Prima rata con trasporto' /></Grid>
						<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.primoCanoneIvato.toFixed(2)} InputLabelProps={{shrink:true}} label='Prima rata con trasporto (IVA inclusa)' /></Grid>
						<Grid item xs={6} md={4/3}><TextField disabled fullWidth value={riepilogo.scadenza2} InputLabelProps={{shrink:true}} label='Scadenza 2' /></Grid>
						<Grid item xs={6} md={4/3}><TextField disabled fullWidth value={riepilogo.scadenza3} InputLabelProps={{shrink:true}} label='Scadenza 3' /></Grid>
						<Grid item xs={6} md={4/3}><TextField disabled fullWidth value={riepilogo.scadenza4} InputLabelProps={{shrink:true}} label='Scadenza 4' /></Grid>

					</>
				}
				{
				( tipo.toUpperCase() === "V" ) &&
					<>
						<Accordion elevation={1} style={{width:'100%'}} expanded={expanded} onChange={handleAccordionChange} >
							<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor:'rgb(214, 190, 156)'}}>
								<Typography>
									Dati vendite
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={1} style={{marginTop:0}}>
									<FormCheckbox xs={12} md={6} control={control} name="accordatura" label="Accordatura" />
									<Grid item xs={12} md={6}></Grid>
									<FormTitle variant="h7">Accessori</FormTitle>
									<FormTextField xs={6} md={4} control={control} name="panchina" label="Panchina" type="text" />
									<FormTextField xs={12} md={4} control={control} name="accessorio1" label="Accessorio 1" type="text" />
									<FormTextField xs={12} md={4} control={control} name="accessorio2" label="Accessorio 2" type="text" />
									<FormTitle variant="h7">Permuta</FormTitle>
									<FormTextField xs={12} md={3} control={control} name="permutaValore" label="Valore" type="number" />
									<FormTextField xs={12} md={3} control={control} name="permutaDataRitiro" label="Ritiro previsto" type="date" />
									<FormSelect xs={12} md={2} control={control} name='permutaMarca' label="Marca" options={marche} valueField='idMarca' labelField='descrizioneMarca' />
									<FormTextField xs={12} md={1} control={control} name="permutaModello" label="Modello" type="text" />
									<FormTextField xs={12} md={1} control={control} name="permutaMatricola" label="Matricola" type="text" />
									<Grid item xs={12} md={2}>
										<Button startIcon={<AddIcon />} variant="contained" component="label" style={{ height: '90%', display: 'flex', alignItems: 'center' }} onClick={() => creaStrumento(cliente, permutaValore, permutaMarca, permutaModello, permutaMatricola)}>Crea strumento da permuta</Button>
									</Grid>									
									
									<FormTitle variant="h7">Acconti/saldo</FormTitle>
									<FormTextField xs={12} md={3} control={control} name="acconto1" label="Acconto alla stipula" type="number" />
									<Grid item xs={12} md={3}>
										<TextField value='Alla stipula' label='in data' disabled InputLabelProps={{ shrink: true }} fullWidth />
									</Grid>
									<FormSelect xs={12} md={4} control={control} name='modalitaAcconto1' label="Modalità dell'acconto" options={contrattiAccontiPagamento} valueField='idAccontoPagamento' labelField='descrizioneAccontoPagamento' />
									<Grid item xs={12} md={2}></Grid>


									<FormTextField xs={6} md={3} control={control} name="acconto2" label="Acconto 2" type="number" />
									<FormTextField xs={6} md={3} control={control} name="dataAcconto2" label="in data" type="date" />
									<FormSelect xs={12} md={4} control={control} name='modalitaAcconto2' label="Modalità dell'acconto" options={contrattiAccontiPagamento} valueField='idAccontoPagamento' labelField='descrizioneAccontoPagamento' />
									<Grid item xs={12} md={2}></Grid>

									<FormTextField xs={6} md={3} control={control} name="acconto3" label="Acconto 3" type="number" />
									<FormTextField xs={6} md={3} control={control} name="dataAcconto3" label="in data" type="date" />
									<FormSelect xs={12} md={4} control={control} name='modalitaAcconto3' label="Modalità dell'acconto" options={contrattiAccontiPagamento} valueField='idAccontoPagamento' labelField='descrizioneAccontoPagamento' />
									<Grid item xs={12} md={2}></Grid>

									<FormTextField xs={6} md={3} control={control} name="acconto4" label="Acconto 4" type="number" />
									<FormTextField xs={6} md={3} control={control} name="dataAcconto4" label="in data" type="date" />
									<FormSelect xs={12} md={4} control={control} name='modalitaAcconto4' label="Modalità dell'acconto" options={contrattiAccontiPagamento} valueField='idAccontoPagamento' labelField='descrizioneAccontoPagamento' />
									<Grid item xs={12} md={2}></Grid>

									<FormTextField xs={12} md={3} control={control} name="saldo" label="Saldo" type="number" controlProps={{ inputProps: { style: { backgroundColor: 'rgba(0, 255, 0, 0.4)' } } }}/>
									<FormTextField xs={6} md={3} control={control} name="dataSaldo" label="in data" type='date' />
									<FormSelect xs={12} md={4} control={control} name='modalitaSaldo' label="Modalità del saldo" options={contrattiAccontiPagamento} valueField='idAccontoPagamento' labelField='descrizioneAccontoPagamento' />									
									<Grid item xs={12} md={2}></Grid>

									{/*
									<Grid item xs={12} md={6}>
										<Button startIcon={<PrintIcon/>} variant="contained" component="label" style={{ height: '90%', display: 'flex', alignItems: 'center' }} onClick={() => stampaAcquisto()}>Stampa modulo d'acquisto da privato</Button>
									</Grid>
									*/}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</>
				}
				{
				( tipo.toUpperCase() === "R" ) &&
					<>
						<FormTextField xs={12} md={12} control={control} name='descrizione' label="Descrizione intervento" type="text" multiline/>
					</>
				}
				<FormTitle variant="h6">Note</FormTitle>
				<FormTextField xs={12} md={12} control={control} name="note" label="Note" type="text" rows={3} multiline />

				<FormTitle variant="h6">Contratti PA</FormTitle>
				<FormTextField xs={6} md={2} control={control} name="odaRif" label="Riferimento ODA" type="text" />
				<FormTextField xs={6} md={2} control={control} name="odaData" label="Data ODA" type="date" />
				<FormTextField xs={6} md={2} control={control} name="codiceCommessa" label="Codice commessa" type="text" />
				<FormTextField xs={6} md={2} control={control} name="dataContratto" label="Data contratto" type="date" />

				<FormTextField xs={6} md={2} control={control} name="cig" label="CIG" type="text" />
				<FormTextField xs={6} md={2} control={control} name="cup" label="CUP" type="text" />

				<FormTitle variant="h6">Testo contratto</FormTitle>
				<FormTextField xs={12} md={8} control={control} name="contrattoModello" label="Modello contratto" type="text" disabled={true} />
				<Grid item xs={6} md={2}>
					<Button startIcon={ isTestoModelloVisible? <VisibilityOffIcon/> : <VisibilityIcon /> } variant="contained" component="label" style={{ height: '90%', display: 'flex', alignItems: 'center' }} onClick={() => setTestoModelloVisible(old=>!old)}>{isTestoModelloVisible ? 'Nascondi' : 'Mostra'} testo modello</Button>
				</Grid>
				<Grid item xs={6} md={2}>
					<Button startIcon={<DownloadIcon/>} variant="contained" component="label" style={{ height: '90%', display: 'flex', alignItems: 'center' }} onClick={() => setDialogModelliOpen(true)}>Carica nuovo modello</Button>
				</Grid>
				
				{isTestoModelloVisible && <Grid item xs={12}>
					<FormTinyMCE xs={12} md={12} control={control} name='contrattoTesto' customButton={()=>setDialogCampiOpen(true)} />
				</Grid>
				}

				<FormTitle variant="h6">Archivio</FormTitle>
				<Archivio tipo="contratti" id={id} />

				<ScadenzeContratto idContratto={id} salvaScadenze={salvaScadenze} />

				{id!=='new' && 
				<>
					<FormTitle variant="h6">DDT emessi</FormTitle>
					<Grid item xs={12} >
						<SimpleDataGrid
							idField="idDocumento"
							getUrl={"/contratti/"+id+"?tipoDocumento=DDT&sort=documenti.data"}
							columns={columnsDDT}
							allowAdd={false}
							allowDelete={false}
							setContextCommands={() => {}}
						/>
					</Grid>
				</>}

				{id!=='new' && 
				<>
					<FormTitle variant="h6">Esiti invii mail</FormTitle>
					<Grid item xs={12} >
						<SimpleDataGrid
							idField="id"
							getUrl={"/contratti/"+id+"/mail?sort=dataOraInvio desc"}
							columns={columnsEsiti}
							allowAdd={false}
							allowDelete={false}
							setContextCommands={() => {}}
						/>
					</Grid>
				</>}

				
			</Grid>
    	</form>
	);
}
