import React, { useState, useEffect, useCallback } from 'react';
import api from 'lib/api2';
import Filtro from './Filtro';
import useNotification from 'components/useNotification';
import Loader from '../components/Loader';
import { DataGridPro } from '@mui/x-data-grid-pro';
import qsBuilder from 'lib/qsBuilder';
import moment from 'moment';
import ContextCommand from 'components/ContextCommand';
import { IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function Pagamenti({setTitle, setContextCommands}) {
	const { pushNotification } = useNotification();
	const [ isLoaderOpen, setLoaderOpen] = useState(false);
	const [ selectedRows, setSelectedRows ] = useState([]);
	const [ rows, setRows ] = useState([]);
	const [ filtro, setFiltro ] = useState('');
	const [ riepilogo, setRiepilogo ] = useState({totale:0, nsel:0, nrighe:0, totaleSel:0});
	const [ reloadData, setReloadData ] = useState(false);

	const columns=[
		{ field: 'idDocumento', headerName: 'ID', width: 80, },
		{ field: 'cognome', headerName: 'Cliente', flex:1, minWidth: 200, valueGetter: p=> p.row.cognome + ' ' + p.row.nome},
		{ field: 'descrizioneTipoContratto', headerName: 'Tipo contratto', width: 140, },
		{
			field: 'idContratto',
			headerName: 'Contratto',
			width: 160,
			renderCell: (params) => params.row.idContratto && (
			  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>{params.row.idContratto}</span>
				<IconButton onClick={() => window.open('/contratti/'+params.row.tipoContratto+"/"+params.row.idContratto)}>
				  <OpenInNewIcon />
				</IconButton>
			  </div>)
		},		
		{
			field: '_1',
			headerName: 'Documento',
			width: 160,
			renderCell: (params) => 
			  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>{params.row.tipoDocumento} {params.row.numero}/{moment(params.row.data).format('YYYY')}</span>
				<IconButton onClick={() => window.open('/documenti/'+params.row.idDocumento)}>
				  <OpenInNewIcon />
				</IconButton>
			  </div>
		},
		{ field: 'data', headerName: 'Data', width: 100, valueFormatter: v=> v.value ? moment(v.value).format('DD/MM/YYYY') : '' },
		{ field: 'totale', headerName: 'Importo', width: 100, align: 'right', valueFormatter: v=>parseFloat(v.value || 0).toFixed(2)},
		{ field: 'pagata', headerName: 'Pagata', width: 80,  valueGetter:params=>(params.value === "1") ? "Sì" : "No"  },
		{ field: 'dataPagamento', headerName: 'Data pagamento', width: 100, valueFormatter: v=> v.value ? moment(v.value).format('DD/MM/YYYY') : '' },
	]

	const setPagato=useCallback(()=>{
		var data='';
		do {
			const msg=(data?'Data ' + data + ' non valida.\n':'')+'Inserire la data di valorizzazione:';
			data=window.prompt(msg,moment().format('DD-MM-YYYY'));
		} while (data && !moment(data,'DD-MM-YYYY').isValid())
		if (!data) return;
		const dataPagamento=moment(data,'DD-MM-YYYY').format('YYYY-MM-DD');
		const postData=selectedRows.map(r=>({idDocumento:r,dataPagamento,pagata:1,_action:'e'}));
		api.post("/documenti/raw", postData).then(data=>{
			pushNotification("Pagamenti assegnati","success");
			setReloadData(prev => !prev)
		}, err=>{
			pushNotification("Errore","error");
			console.error(err);
		})
	}, [pushNotification, selectedRows]);

	useEffect(() => {
		setTitle('Pagamenti');
		const contextCommands=[];
		contextCommands.push(<ContextCommand key="1" icon="cash" onClick={ setPagato }>Imposta pagato</ContextCommand>);
		setContextCommands(contextCommands);
	}, [setTitle, setContextCommands, setPagato]);
	
	useEffect(()=>{
		if (filtro === '') return;
        setLoaderOpen(true);
		api.get("/pagamenti?sort=data,cognome,nome&"+qsBuilder(filtro)).then(righe => {
		  setRows(righe);
		  setLoaderOpen(false);
		});
	}, [filtro, reloadData])

	useEffect(()=>{
		//n tot, n selezionati, totale, totale selezionati, iva, iva selezionati
		const nrighe=rows.length;
		const nsel=selectedRows.length;
		const totale=rows.reduce((acc, {totale}) => parseFloat(totale || 0) + acc, 0);
		const righeSelezionate=rows.filter(r=>selectedRows.includes(r.idDocumento));
		const totaleSel=righeSelezionate.reduce((acc, {totale}) => parseFloat(totale || 0) + acc, 0);
		setRiepilogo({totale, nsel, nrighe, totaleSel});
	}, [rows, selectedRows]);

	return (
		<div>
			<Loader open={isLoaderOpen} />
			<Filtro filtra={setFiltro} />
			<div style={{height:'70vh'}}>
			<DataGridPro
					rows={rows}
					columns={columns}
					autoHeight={false}
					getRowId={r=>r.idDocumento}
					onSelectionModelChange={selected => setSelectedRows(selected)}
					rowSelectionModel={selectedRows}
					isRowSelectable={ (params) => (params.row.pagata === "" || params.row.pagata === "0") }
					checkboxSelection={true}
				/>
			</div>
			<Grid container spacing={1} style={{marginTop:50}}>
				<Grid item xs={6} md={1}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.nrighe} InputLabelProps={{shrink:true}} label='Righe' /></Grid>
				<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.totale.toFixed(2)} InputLabelProps={{shrink:true}} label='Totale' /></Grid>
				<Grid item xs={6} md={1}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.nsel} InputLabelProps={{shrink:true}} label='Righe selezionate' /></Grid>
				<Grid item xs={6} md={2}><TextField disabled fullWidth inputProps={{style: { textAlign: 'right' }}} value={riepilogo.totaleSel.toFixed(2)} InputLabelProps={{shrink:true}} label='Totale selezionate' /></Grid>
			</Grid>
		</div>
	);
}